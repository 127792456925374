import React from 'react'

export default function Cell({info}) {

    const svgs = {
        opened: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280.417 280.417" width="16" height="16"><path fill='white' d="M206.527 4.542c-40.742 0-73.889 33.146-73.889 73.889v32.111H16.125C7.22 110.542 0 117.761 0 126.667V259.75c0 8.906 7.22 16.125 16.125 16.125h165.25c8.906 0 16.125-7.219 16.125-16.125V126.667c0-8.906-7.219-16.125-16.125-16.125h-8.736V78.431c0-18.686 15.202-33.889 33.889-33.889s33.89 15.202 33.89 33.889c0 11.046 8.954 20 20 20s20-8.954 20-20c-.001-40.742-33.148-73.889-73.891-73.889m-92.111 190.235v26.432c0 8.652-7.014 15.666-15.666 15.666s-15.666-7.014-15.666-15.666v-26.432c-5.885-4.639-9.668-11.826-9.668-19.901 0-13.992 11.342-25.334 25.334-25.334s25.334 11.342 25.334 25.334c0 8.075-3.783 15.262-9.668 19.901"/></svg>,
        closed: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 198 272" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M98.75 0.00343312C98.5007 0.00114727 98.2507 0 98 0V0.0137787C44.1425 1.00656 24.861 55.3739 24.861 73.889V106H16.125C7.22 106 0 113.219 0 122.125V255.208C0 264.114 7.22 271.333 16.125 271.333H181.375C183.602 271.333 185.723 270.882 187.652 270.066C190.563 268.835 193.037 266.773 194.777 264.178C196.497 261.614 197.5 258.528 197.5 255.208V122.125C197.5 121.012 197.387 119.925 197.172 118.875C195.998 113.137 191.776 108.509 186.271 106.757C184.727 106.265 183.082 106 181.375 106H172.639V73.889C172.639 55.3739 153.358 1.00656 99.5 0.0137787V0C99.2493 0 98.9993 0.00114727 98.75 0.00343312ZM98.75 40.0117C72 40.0117 64.862 62.6502 64.862 73.889V106H132.638V73.889C132.638 62.6502 125 40.0117 98.75 40.0117ZM124.084 170.334C124.084 178.409 120.301 185.596 114.416 190.235V216.667C114.416 225.319 107.402 232.333 98.75 232.333C90.098 232.333 83.084 225.319 83.084 216.667V190.235C77.199 185.596 73.416 178.409 73.416 170.334C73.416 156.342 84.758 145 98.75 145C112.742 145 124.084 156.342 124.084 170.334Z" fill="white"/></svg>,
        warning: <svg width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="M19.59 15.86 12.007 1.924C11.515 1.011 10.779.5 9.989.5s-1.515.521-2.016 1.434L.409 15.861c-.49.901-.544 1.825-.138 2.53.405.707 1.216 1.109 2.219 1.109h15.02c1.003 0 1.814-.402 2.22-1.108.405-.706.351-1.619-.14-2.531M10 4.857c.395 0 .715.326.715.728v6.583c0 .402-.32.728-.715.728a.72.72 0 0 1-.715-.728V5.584c0-.391.32-.728.715-.728m0 11.624c-.619 0-1.11-.51-1.11-1.14s.502-1.141 1.11-1.141c.619 0 1.11.51 1.11 1.14S10.607 16.48 10 16.48"/></svg>
    }

    const warning = !info.empty && !info.open && !info.seal;

    return(
        <div className={`rounded pt-1 pb-2 px-2 ${warning ? 'bg-warning' : info.empty ? 'bg-success' : 'bg-error'} text-white/80 flex flex-col select-none`}>
            <div className='flex justify-between items-center'>
                <div className='text-white text-xl font-bold'>{info.id}</div>
                <div className=''>{warning ? svgs.warning : info.open ? svgs.opened : svgs.closed}</div>
            </div>
            <div className='flex flex-col text-[16px] leading-5 text-left'>
                <div>PIN: <span className='select-all'>{info.pin}</span></div>
                <div>Замовлення: <span className='select-all'>{info.order}</span></div>
                <div>СМС: <span className='select-all'>{info.tel_num}</span></div>
                <div>Сума: <span className='select-all'>{info.pay_sum}</span></div>
            </div>
        </div>
    )
}