import React, { useEffect, useState } from 'react';
import axios from 'axios';
import apteks from './json/apteks.json';
import Cell from './components/Cell';

export default function App() {
	const [token] = useState('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzY4LCJpYXQiOjE2OTI5Njg2OTIsImV4cCI6MjU1Njk2ODY5Mn0.Qb7yvQDj_haDbHpKVbzTgwDvT4KIA_VCHV8VNlE_-bQ');
	const [pharmacies, setPharmacies] = useState([]);
	const [selectedPharmacy, setSelectedPharmacy] = useState(null);
	const [selectedLocker, setSelectedLocker] = useState(null);
	const [cells, setCells] = useState([]);
	const [cellError, setCellError] = useState(false);
	const [cellOpenMode, setCellOpenMode] = useState(false);
	const [cellClearMode, setCellClearMode] = useState(false);
	const [cellToOpen, setCellToOpen] = useState();
	const [cellToClear, setCellToClear] = useState();
	const [lockerSearch, setLockerSearch] = useState('');

	const svgs = {
        opened: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280.417 280.417" width="12" height="12"><path fill='white' d="M206.527 4.542c-40.742 0-73.889 33.146-73.889 73.889v32.111H16.125C7.22 110.542 0 117.761 0 126.667V259.75c0 8.906 7.22 16.125 16.125 16.125h165.25c8.906 0 16.125-7.219 16.125-16.125V126.667c0-8.906-7.219-16.125-16.125-16.125h-8.736V78.431c0-18.686 15.202-33.889 33.889-33.889s33.89 15.202 33.89 33.889c0 11.046 8.954 20 20 20s20-8.954 20-20c-.001-40.742-33.148-73.889-73.891-73.889m-92.111 190.235v26.432c0 8.652-7.014 15.666-15.666 15.666s-15.666-7.014-15.666-15.666v-26.432c-5.885-4.639-9.668-11.826-9.668-19.901 0-13.992 11.342-25.334 25.334-25.334s25.334 11.342 25.334 25.334c0 8.075-3.783 15.262-9.668 19.901"/></svg>,
        closed: <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 198 272" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M98.75 0.00343312C98.5007 0.00114727 98.2507 0 98 0V0.0137787C44.1425 1.00656 24.861 55.3739 24.861 73.889V106H16.125C7.22 106 0 113.219 0 122.125V255.208C0 264.114 7.22 271.333 16.125 271.333H181.375C183.602 271.333 185.723 270.882 187.652 270.066C190.563 268.835 193.037 266.773 194.777 264.178C196.497 261.614 197.5 258.528 197.5 255.208V122.125C197.5 121.012 197.387 119.925 197.172 118.875C195.998 113.137 191.776 108.509 186.271 106.757C184.727 106.265 183.082 106 181.375 106H172.639V73.889C172.639 55.3739 153.358 1.00656 99.5 0.0137787V0C99.2493 0 98.9993 0.00114727 98.75 0.00343312ZM98.75 40.0117C72 40.0117 64.862 62.6502 64.862 73.889V106H132.638V73.889C132.638 62.6502 125 40.0117 98.75 40.0117ZM124.084 170.334C124.084 178.409 120.301 185.596 114.416 190.235V216.667C114.416 225.319 107.402 232.333 98.75 232.333C90.098 232.333 83.084 225.319 83.084 216.667V190.235C77.199 185.596 73.416 178.409 73.416 170.334C73.416 156.342 84.758 145 98.75 145C112.742 145 124.084 156.342 124.084 170.334Z" fill="white"/></svg>,
		warning: <svg width="18" height="18" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path className="fill-primary" d="M19.59 15.86 12.007 1.924C11.515 1.011 10.779.5 9.989.5s-1.515.521-2.016 1.434L.409 15.861c-.49.901-.544 1.825-.138 2.53.405.707 1.216 1.109 2.219 1.109h15.02c1.003 0 1.814-.402 2.22-1.108.405-.706.351-1.619-.14-2.531M10 4.857c.395 0 .715.326.715.728v6.583c0 .402-.32.728-.715.728a.72.72 0 0 1-.715-.728V5.584c0-.391.32-.728.715-.728m0 11.624c-.619 0-1.11-.51-1.11-1.14s.502-1.141 1.11-1.141c.619 0 1.11.51 1.11 1.14S10.607 16.48 10 16.48"/></svg>
	}

	useEffect(() => {
		// Define the function to fetch data
		const fetchPharmacies = async () => {
			try {
				const response = await axios.get(
					'https://tmcu.lll.org.ua/help_api/parcel/machine/get/list',
					{
						headers: {
							Authorization: `Bearer ${token}`, // Replace with your actual token
						},
					}
				);

				// Extract pharmacyId from the API response
				const pharmacyData = response.data;

				// Create a map to handle multiple parcel lockers in the same pharmacy
				const pharmacyMap = {};

				// Iterate over the data and map pharmacy IDs to their corresponding names and parcel lockers
				pharmacyData.forEach((pharmacy) => {
					const apteka = apteks.find((apteka) => apteka.id === pharmacy.pharmacyId);
					if (apteka) {
						// If the pharmacy is already in the map, append the new parcel locker
						if (pharmacyMap[apteka.id]) {
							pharmacyMap[apteka.id].parcelLockers.push({
								id: pharmacy.id,
								ip: pharmacy.ipParcelMachine,
							});
						} else {
							// If it's a new pharmacy, add it to the map with its first parcel locker
							pharmacyMap[apteka.id] = {
								...apteka,
								parcelLockers: [
								{
									id: pharmacy.id,
									ip: pharmacy.ipParcelMachine,
								},
								],
							};
						}
					}
				});

				// Convert the map back to an array
				const uniquePharmacies = Object.values(pharmacyMap);

				setPharmacies(uniquePharmacies);
				console.log('> list got');
			} catch (error) {
				console.error('Error fetching the pharmacies:', error);
			}
		};

		// Call the fetch function when the component mounts
		fetchPharmacies();
	}, []);

	const fetchCells = async (lockerId) => {
		try {
			const response = await axios.get(
				`https://tmcu.lll.org.ua/help_api/remote/parcel_machine/get/cells/machine_id/${lockerId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`, // Replace with your actual token
					},
				}
			);
			const cellData = response.data;
			setCells(Object.entries(cellData).map(([id, info]) => ({ id, ...info })));
			setCellError(null);
			console.log('> cells got');
		} catch (error) {
			console.error('Error fetching cells:', error);
			setCellError(error);
		}
	}

	const handlePharmacySelect = (e) => {
		const selected = JSON.parse(e.target.value);
		setSelectedPharmacy(selected);
		setSelectedLocker(selected.parcelLockers[0]);
		fetchCells(selected.parcelLockers[0].id);
	};

	const handleLockerSelect = (locker) => {
		setSelectedLocker(locker);
		fetchCells(locker.id);
	};

	const handleRefreshClick = () => {
		if (selectedLocker) {
			fetchCells(selectedLocker.id); // Refetch cells for the selected locker
		}
	};

	const handleCellOpenModeChange = () => {
		setCellOpenMode(!cellOpenMode);
		setCellClearMode(false);
	}

	const handleCellClearModeChange = () => {
		setCellClearMode(!cellClearMode);
		setCellOpenMode(false);
	}

	const handleLockerSearchChange = (e) => {
		const searchValue = e.target.value.trim();
		setLockerSearch(searchValue);
		
		if (searchValue) {
			// Знайдемо аптеку, яка містить поштомат з введеним номером
			const foundPharmacy = pharmacies.find(pharmacy => 
				pharmacy.parcelLockers.some(locker => locker.id === Number(searchValue))
			);
			
			if (foundPharmacy) {
				setSelectedPharmacy(foundPharmacy);
				const foundLocker = foundPharmacy.parcelLockers.find(locker => locker.id === Number(searchValue));
				setSelectedLocker(foundLocker);
				fetchCells(foundLocker.id);
			}
			
			handleRefreshClick();
		} else {
			// Очистимо стан, якщо поле пошуку порожнє
			setSelectedPharmacy(null);
			setSelectedLocker(null);
		}
	};

	const openCell = async (cell) => {
		try {
			const response = await axios.get(`http://tmcu.lll.org.ua/postomat_api/machine/${selectedLocker.id}/cells/${cell.id}/inventory`);
			setCellToOpen(cell);
			document.getElementById('modalOpenedCell').showModal();
			handleRefreshClick();
			console.log(`> cell ${selectedLocker.id}\\${cell.id} opened`);
		} catch (error) {
			console.error('Error fetching cells:', error);
			setCellError(error);
		}
	}

	const clearCell = async (cell) => {
		try {
			const response = await axios.get(
				`https://tmcu.lll.org.ua/help_api/remote/parcel_machine/clear/machine_id/${selectedLocker.id}/cell/${cell.id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`, // Replace with your actual token
					},
				}
			);
			setCellToClear(cell);
			document.getElementById('modalClearedCell').showModal();
			handleRefreshClick();
			console.log(`> cell ${selectedLocker.id}\\${cell.id} cleared`);
		} catch (error) {
			console.error('Error fetching cells:', error);
			setCellError(error);
		}
	}

	return (
		<div className="p-8 flex flex-col gap-4">

			<div className="bg-base-100 p-4 rounded-xl flex items-center justify-between">
				<select className="select select-bordered w-full max-w-xs" onChange={handlePharmacySelect}>
					<option disabled selected>Оберіть аптеку</option>
					{pharmacies.map((pharmacy) => (
						<option key={pharmacy.id} value={JSON.stringify(pharmacy)}>
							{String(pharmacy.id).padStart(4, 0)} | {pharmacy.name}
						</option>
					))}
				</select>
				<input type="text" placeholder="Введіть номер поштомату" className="input input-bordered w-full max-w-xs" value={lockerSearch} onChange={handleLockerSearchChange}/>
			</div>

			<div className={`bg-base-100 p-4 rounded-xl ${cellOpenMode || cellClearMode ? 'ring-2 border-4 ring-error border-error p-3' : 'ring-none border-none'}`}>
				{selectedPharmacy ? 
					<div className='flex flex-col gap-2'>
						<div className='flex justify-between items-center'>
							<div className='text-2xl font-bold text-primary'>{selectedPharmacy.name}</div>
							{selectedLocker && (
								<div className='flex gap-2 items-center'>
									<div className="form-control">
										<label className="label cursor-pointer flex gap-2">
											<span className="label-text">Режим очищення комірок</span>
											<input type="checkbox" className="toggle toggle-primary" checked={cellClearMode} onChange={handleCellClearModeChange}/>
										</label>
									</div>
									<div className="form-control">
										<label className="label cursor-pointer flex gap-2">
											<span className="label-text">Режим відкриття комірок</span>
											<input type="checkbox" className="toggle toggle-primary" checked={cellOpenMode} onChange={handleCellOpenModeChange}/>
										</label>
									</div>
									<button className='btn btn-primary' onClick={handleRefreshClick}>Оновити інформацію</button>
								</div>
							)}
						</div>
						<div className='flex justify-between'>
							<div className='flex gap-2 items-center'>
								<div>Поштомат:</div>
								<div role="tablist" className="max-w-max tabs tabs-boxed">
									{selectedPharmacy.parcelLockers.map((locker) => (
										<a role="tab" className={`tab ${selectedLocker === locker ? 'tab-active' : ''}`} key={locker.id} onClick={() => handleLockerSelect(locker)}>{locker.id}</a>
									))}
								</div>
							</div>
							<div className='flex gap-2 items-center'>
								<div>Комірка:</div>
								<div className="badge badge-outline badge-success flex gap-2 select-none"><div className='w-[0.5rem] h-[0.5rem] rounded-full bg-success'></div>Вільна</div>
								<div className="badge badge-outline badge-error flex gap-2 select-none"><div className='w-[0.5rem] h-[0.5rem] rounded-full bg-error'></div>Зайнята</div>
								<div className="badge badge-outline badge-warning flex gap-2 select-none"><div className='w-[0.5rem] h-[0.5rem] rounded-full bg-warning'></div>Проблема</div>
								<div className="badge badge-outline badge-base-100 flex gap-2 select-none">{svgs.opened}Відчинена</div>
								<div className="badge badge-outline badge-base-100 flex gap-2 select-none">{svgs.closed}Зачинена</div>
							</div>
						</div>
						{selectedLocker ? 
							cellError ? 
								<div className='flex flex-col gap-2'>
									<p>Не вдалось отримати інформацію про поштомат №{selectedLocker.id}:</p>
									<div className='mockup-code bg-base-300'>
										<div className='ml-4'>{'{'}</div>
											{Object.keys(cellError).map(key => (
												<pre className='px-4'><code>{key}: {JSON.stringify(cellError[key])}</code></pre>
											))}
										<div className='ml-4'>{'}'}</div>
									</div>
								</div>
								:
								<div className="flex flex-col gap-2">
									<p>IP: {selectedLocker.ip}</p>
									<div className='grid grid-cols-8 gap-2'>
										{cells.map((cell) => (
											cellOpenMode ? 
												<>
													<button onClick={() => {openCell(cell)}}>
														<Cell key={cell.id} info={cell}/>
													</button>
												</> 
												:
												cellClearMode ?
													<>
														<button onClick={() => {clearCell(cell)}}>
															<Cell key={cell.id} info={cell}/>
														</button>
													</>
													:
													<Cell key={cell.id} info={cell}/>
										))}
									</div>
									<dialog id={`modalOpenedCell`} className="modal">
										<div className="modal-box">
											<h3 className="font-bold text-lg flex gap-2 items-center text-primary">{svgs.warning} Увага! Комірка була відчинена!</h3>
											<p className="pt-2">Комірка №{cellToOpen ? cellToOpen.id : ''}</p>
											<p>Поштомат №{selectedLocker.id}</p>
											<p>Аптека: {selectedPharmacy.apteka}</p>
											<div className="modal-action">
												<form method="dialog">
													<button className="btn btn-primary">Зрозуміло</button>
												</form>
											</div>
										</div>
									</dialog>
									<dialog id={`modalClearedCell`} className="modal">
										<div className="modal-box">
											<h3 className="font-bold text-lg flex gap-2 items-center text-primary">{svgs.warning} Увага! Комірка була очищена!</h3>
											<p className="pt-2">Комірка №{cellToClear ? cellToClear.id : ''}</p>
											<p>Поштомат №{selectedLocker.id}</p>
											<p>Аптека: {selectedPharmacy.apteka}</p>
											<div className="modal-action">
												<form method="dialog">
													<button className="btn btn-primary">Зрозуміло</button>
												</form>
											</div>
										</div>
									</dialog>
								</div>
							:
							null
						}
					</div>
					:
					<div>Оберіть аптеку</div>
				}
			</div>
			
		</div>
	);
}
